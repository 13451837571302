<template>
  <b-container fluid>
    <form @submit.prevent="saveData">
      <b-row>
        <b-col sm="12">
          <card>
            <template v-slot:headerTitle>
              <!-- <h4 class="card-title"><b>MODIFICAR DEPARTAMENTO</b></h4> -->
            </template>
            <h4 class="px-4 py-4"><b>Editar numeración</b></h4>
            <div class="row px-5">
              <div class="col-md-6">
                <div class="mb-4">
                  <label for=" " class="form-label">Tipo de documento</label>
                  <b-form-select :options="ListaTipoDocumento" required v-model="item.tipo_documento" value-field="id" text-field="nombre"></b-form-select>
                </div>
              </div>
              <div class="col-md-6">
                <div>
                  <label for=" " class="form-label">Prefijo</label>
                  <input v-model="item.prefijo" required placeholder="" type="text" class="form-control" id=" " />
                </div>
              </div>
              <div class="col-md-6">
                <div>
                  <label for=" " class="form-label">Número inicial</label>
                  <input v-model="item.numero_inicial" required placeholder="" type="text" class="form-control" id=" " />
                </div>
              </div>
              <div class="col-md-6 mb-5">
                <div>
                  <label for=" " class="form-label">Nombre</label>
                  <input v-model="item.nombre" required placeholder="" type="text" class="form-control" id=" " />
                </div>
              </div>
            </div>
          </card>
        </b-col>
      </b-row>
      <div class="text-right px-5 pb-4">
        <button type="submit" class="mx-1 btn px-4 py-2 btn-primary">Guardar Numeración</button>
        <button class="mx-1 btn px-4 py-2 btn-secondary" @click="$router.push('/helex/numeraciones')">Cancelar</button>
      </div>
    </form>
  </b-container>
</template>
<script>
export default {
  name: "Numeracion_editar",
  data() {
    return {
      usuario: { rol: { nivel: 0 } },
      id: "",
      selected: null,
      item: {
        // EmpresaId: "",
        Eliminado: 0,
      },
      ListaTipoDocumento: [
        {id:0, nombre:"Cancelación de ajuste"},
        {id:1, nombre:"Nómina electrónica"},
        {id:2, nombre:"Cancelación de Nómina"}
        ],
      
    };
  },
  async mounted() {
    this.id = this.$route.params.id
    this.$isLoading(true);
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    //this.item.EmpresaId = this.usuario.empresa.id;
    await this.getData()
    this.$isLoading(false);
  },
  methods: {
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Numeracion/GetNumeracion/" + this.id,
        });
        if (res) {
          this.item = res;
        } else {
          this.item = {};
        }
      } catch (error) {
        this.item = {};
        console.log("err", error);
      }
    },    
    async saveData() {
      console.log("saving", this.item);
      try {
        //this.item.EmpresaId = this.item.empresa.id;
        // this.item.municipio.departamentoPaisId = this.item.municipio.departamentoPais.id
        //this.item.Descripcion = this.item.descripcion;
        //this.item.SucursalId = this.item.sucursal.id;
        let res = await this.$store.dispatch("hl_post", {
          path: "Numeracion/Numeracion/",
          data: this.item,
        });
        console.log("post empresas...", res);
        if (res) this.$router.push("/helex/Numeraciones");
        else alert("Verificar");
      } catch (error) {
        this.item = {};
        console.log("err", error);
      }
    },
  },
};
</script>
